import React from 'react';
import ErrorPage from '../Components/404';

export default function ErrorFallbackPage() {
   
    return (

        <div>
       
        <ErrorPage />
        
            
        </div>
    )
}
