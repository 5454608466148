import React from 'react';
import SSCSect from '../Components/SSCSect';

export default function Home() {
   
    return (

        <div>
        <SSCSect />
          
        
        
            
        </div>
    )
}
